import './index.scss'

import React from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

import { formatPrice } from '../../lib/format'

// import { Container } from '@components'

const bem = new BEMHelper('sum')

export default function Sum({ label, value, details, vatDeducted = false }) {
  return (
    <div {...bem('')}>
      <div {...bem('content')}>
        <span {...bem('label')}>{label}:</span>
        <span {...bem('value')}>{formatPrice(value, vatDeducted)} kr</span>
      </div>
      {details || vatDeducted ? <div {...bem('details')}>
        {details}
        {details && vatDeducted ? <br/> : null}
        {vatDeducted ? 'Priser eks. MVA' : null}
      </div> : null}
    </div>
  )
}

Sum.propTypes = {
  label: T.string.isRequired,
  details: T.string,
  value: T.oneOfType([T.string, T.number]).isRequired,
  vatDeducted: T.bool,
}
